import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Slider from '../components/slider';
import SEO from '../components/seo';

const FotoProject = ({ data }) => {
  const { content, chapter, slug } = data.fotoProject;
  const { backgroundColor } = data.fotoPage;

  const slugLink = chapter[0]
    ? `/foto/${slug.current}/${chapter[0]?.slug.current}`
    : false;

  return (
    <Layout color={backgroundColor.hex}>
      <SEO siteTitle="foto" />
      <Slider content={content} slugLink={slugLink} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    fotoProject: sanityFotoProject(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      chapter {
        slug {
          current
        }
      }
      content {
        ... on SanityMainImage {
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          portrait
          square
          spacing
          credits
        }
        ... on SanityImageGallery {
          images {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          spacing
        }
        ... on SanityMainText {
          _rawText
          spacing
        }
      }
    }
    fotoPage: sanityFotosPage {
      backgroundColor {
        hex
      }
    }
  }
`;

FotoProject.propTypes = {
  data: PropTypes.object,
};

export default FotoProject;
